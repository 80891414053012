import {
  WYSIWYG_EDITOR,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES = { action: 'change', subject: 'Templates' }

  const fields = {
    invitation: {
      type: WYSIWYG_EDITOR,
      label: 'Template',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },

    verification: {
      type: WYSIWYG_EDITOR,
      label: 'Template',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },

  }

  return {
    fields,
    ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES,
  }
}
