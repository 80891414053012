<template>
  <div>
    <b-form>
      <label class="font-weight-bolder text-dark font-medium-5 mb-1">{{ $t('Invitation to open web account ') }}</label>
      <b-row>
        <b-col md="12">
          <span class="inputLabel">{{ fields['invitation'].label }}</span>
          <b-skeleton
            height="200px"
            style="border-radius: 5px"
          />
        </b-col>
      </b-row>
      <label class="font-weight-bolder text-dark font-medium-5 mb-1">{{ $t('Verification information missing template ') }}</label>
      <b-row>
        <b-col md="12">
          <span class="inputLabel">{{ fields['verification'].label }}</span>
          <b-skeleton
            height="200px"
            style="border-radius: 5px"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>

</template>
<script>
import {
  BRow, BSkeleton, BCol, BForm,
} from 'bootstrap-vue'
import config from '../templatesConfig'

export default {
  name: 'Skeleton',
  components: {
    BForm,
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
