export default {
  GET(state, data) {
    state.template = { ...data }
  },
  LIST(state, data) {
    state.templates = data
  },
  SET_FORM(state, data) {
    state.templatesForm = { ...data }
  },
}
