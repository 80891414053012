<template>
  <div>
    <skeleton v-if="loading" />
    <b-form v-else>
      <div class="d-flex justify-content-end">
        <feather-icon
          v-if="organizationRequiresEditConfirmation"
          v-b-tooltip.noninteractive.hover.bottom
          :title="$t('Edit')"
          :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
          size="36"
          class="ml-1 cursor-pointer"
          @click="isEditAble()"
        />
      </div>
      <validation-observer
        ref="templateCreateVal"
      >
        <label class="font-weight-bolder text-dark font-medium-5 mb-1">{{ $t('Invitation to open web account ') }}</label>
        <b-row>
          <b-col md="12">
            <component
              :is="fields['invitation'].type"
              v-model="template['invitation']"
              v-bind="getProps('invitation')"
              :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES.action,ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES.subject) || isEdit"
            />
          </b-col>
        </b-row>
        <label class="font-weight-bolder text-dark font-medium-5 mb-1">{{ $t('Verification information missing template ') }}</label>
        <b-row>
          <b-col md="12">
            <component
              :is="fields['verification'].type"
              v-model="template['verification']"
              v-bind="getProps('verification')"
              :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES.action,ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES.subject) || isEdit"
            />
          </b-col>
        </b-row>

        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="routeLink"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                v-if="$can(ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES.action,ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES.subject)"
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import { scrollToError } from '@core/utils/utils'
import { required } from '@/libs/validations/validations'
import store from '@/store'
import Skeleton from './Skeletons.vue'
import config from '../templatesConfig'

export default {
  name: 'TemplatesForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BForm,
    Skeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      loading: true,
      onSubmit: false,
      template: {},
      templateClone: {},
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    templateForm() {
      return this.$store.state[this.MODULE_NAME].templatesForm
    },
    templateFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].templateFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.templateClone) !== JSON.stringify(this.template)
    },
  },

  created() {
    this.load()
  },

  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    load() {
      this.template = {}
      this.templateClone = {}
      this.$store.dispatch(`${this.MODULE_NAME}/get`).then(res => {
        const { data } = res.data
        const template = this.mappingFields(Object.keys(this.fields), data, {
          id: data.id,
          invitation: data.invitation,
          verification: data.verification,
        })
        this.template = { id: template.id, verification: template.verification, invitation: template.invitation }
        this.templateClone = { id: template.id, verification: template.verification, invitation: template.invitation }
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, template)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_TEMPLATE_FORM_CLONE`, template)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },

    submit() {
      this.error = {}
      this.onSubmit = true
      this.$refs.templateCreateVal.validate()
        .then(success => {
          if (success) {
            this.loading = true
            const templateForm = this.template
            this.sendNotification(this, templateForm, `${this.MODULE_NAME}/update`)
              .then(() => {
                this.load()
                this.clear()
                this.loading = false
              })
              .catch(err => {
                this.loading = false
                this.error = err.response?.data
              })
              .finally(() => {
                this.onSubmit = false
              })
          } else {
            this.loading = false
            scrollToError(this, this.$refs.templateCreateVal)
          }
        })
    },

    getProps(fieldName) {
      const data = {
        field: this.fields[fieldName],
        name: fieldName,
      }
      return data
    },

    routeLink() {
      this.$router.push({ name: 'settings' })
    },

    loader() {
      this.$refs.templateCreateVal.reset()
      const smth = JSON.parse(this.templateFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },

    clear() {
      this.$refs.templateCreateVal.reset()
    },

    showConfirmationModal(next) {
      this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },

  },

  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'templates'

    const { fields, ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES } = config()
    return {
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      ACCESS_ABILITY_FOR_SYSTEM_TEMPLATES,
    }
  },
}
</script>

<style scoped>

</style>
