import axios from '@/libs/axios'

const endpoint = 'settings/customer-print-templates'

const get = () => axios.get(`/${endpoint}`)
const update = (ctx, data) => axios.put(`/${endpoint}`, data)

export default {
  get,
  update,
}
