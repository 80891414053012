<template>
  <div>
    <templates-form ref="templates-form" />
  </div>
</template>

<script>
import store from '@/store'
import templatesModule from '@/store/settings/customer/templates'
import { onUnmounted } from '@vue/composition-api'
import TemplatesForm from './components/TemplatesForm.vue'

export default {
  name: 'Templates',
  components: { TemplatesForm },

  created() {
    this.$emit('updateMenu', 'settings-customer-templates')
  },
  beforeRouteLeave(to, from, next) {
    this.$refs['templates-form'].showConfirmationModal(next)
  },
  setup() {
    const MODULE_NAME = 'templates'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, templatesModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>
